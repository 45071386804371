import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter, FaFacebookF, FaYoutube, FaPinterest, FaLinkedinIn, FaPhone, FaEnvelope, FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-section">
          <h3>About Us</h3>
          <p>House of Canvas is a leading tent manufacturer in Tanzania, providing high-quality tents for events, camping, and more.</p>
          <div className="footer-social">
            <a href="https://www.facebook.com/House.Of.Canvas.Limited/" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://www.instagram.com/houseofcanvastz/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://tz.linkedin.com/in/house-of-canvas-07a1402b3" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            <a href="https://twitter.com/houseofcanvas" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://www.youtube.com/channel/UCxxxxxxxxxxx" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
          </div>
        </div>
        
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/products">Products</Link></li>
            <li><Link to="/team">Our Team</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/faq">FAQs</Link></li>
          </ul>
        </div>
        
        <div className="footer-section">
          <h3>Our Services</h3>
          <ul className="footer-links">
            <li><Link to="/services#consultations">Consultations</Link></li>
            <li><Link to="/services#manufacturing">Tent Manufacturing</Link></li>
            <li><Link to="/services#rentals">Tent Rentals</Link></li>
            <li><Link to="/services#furniture">Furniture</Link></li>
            <li><Link to="/services#electrical">Electrical Wiring</Link></li>
          </ul>
        </div>
        
        <div className="footer-section">
          <h3>Contact Info</h3>
          <div className="contact-locations">
            <div className="contact-location">
              <h4>TANZANIA:</h4>
              <ul className="contact-info">
                <li>
                  <FaMapMarkerAlt />
                  <span>Nelson Mandela Rd, Arusha, Tanzania</span>
                </li>
                <li>
                  <FaPhone />
                  <span>+255 765 603 466</span>
                </li>
                <li>
                  <FaEnvelope />
                  <span>info@houseofcanvas.net</span>
                </li>
              </ul>
            </div>
            
            <div className="contact-location">
              <h4>KENYA:</h4>
              <ul className="contact-info">
                <li>
                  <FaPhone />
                  <span>+254 110 279 065</span>
                </li>
                <li>
                  <FaEnvelope />
                  <span>infoke@houseofcanvas.net</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaTwitter, FaFacebookF, FaYoutube, FaPinterest, FaLinkedinIn, FaPhone, FaInstagram } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [logoAnimated, setLogoAnimated] = useState(false);
  const logoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    closeMenu();
  }, [location]);

  useEffect(() => {
    if (!logoAnimated && logoRef.current) {
      logoRef.current.classList.add('logo-entrance');
      setLogoAnimated(true);
      
      const timer = setTimeout(() => {
        if (logoRef.current) {
          logoRef.current.classList.remove('logo-entrance');
        }
      }, 1500);
      
      return () => clearTimeout(timer);
    }
  }, [logoAnimated]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="top-bar">
        <div className="container top-bar-container">
          <div className="contact-info">
            <a href="tel:++255765603466"><FaPhone /> +255 765 603 466</a>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/houseofcanvasltd" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://www.instagram.com/houseofcanvastz/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://www.linkedin.com/company/house-of-canvas-ltd/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            <a href="https://twitter.com/houseofcanvas" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://www.youtube.com/channel/UCxxxxxxxxxxx" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
          </div>
        </div>
      </div>
      
      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" ref={logoRef}>
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="House of Canvas" />
          </Link>
          
          <div className="menu-icon" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
          
          <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <Link to="/" className="nav-link" onClick={() => setIsOpen(false)}>Home</Link>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link">Who We Are</span>
              <div className="dropdown-content">
                <Link to="/about" onClick={() => setIsOpen(false)}>About the Company</Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link">What We Do</span>
              <div className="dropdown-content">
                <Link to="/services#consultations" onClick={() => setIsOpen(false)}>Consultations</Link>
                <Link to="/services#manufacturing" onClick={() => setIsOpen(false)}>Tent Manufacturing</Link>
                <Link to="/services#rentals" onClick={() => setIsOpen(false)}>Tent Rentals</Link>
                <Link to="/services#furniture" onClick={() => setIsOpen(false)}>Furniture</Link>
                <Link to="/services#electrical" onClick={() => setIsOpen(false)}>Electrical Wiring</Link>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/products" className="nav-link" onClick={() => setIsOpen(false)}>Products</Link>
            </li>
            <li className="nav-item">
              <Link to="/gallery" className="nav-link" onClick={() => setIsOpen(false)}>Gallery</Link>
            </li>
            <li className="nav-item">
              <Link to="/faq" className="nav-link" onClick={() => setIsOpen(false)}>FAQs</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" onClick={() => setIsOpen(false)}>Contact Us</Link>
            </li>
            <li className="nav-item nav-cta">
              <Link to="/contact" className="quote-button" onClick={() => setIsOpen(false)}>GET A QUOTE</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar; 
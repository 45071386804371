import { useEffect } from 'react';

/**
 * This hook fixes the "ResizeObserver loop completed with undelivered notifications" error
 * that can occur with certain components like Google Maps or other third-party widgets.
 */
const useResizeObserverFix = () => {
  useEffect(() => {
    // Create a more robust handler for the error
    const errorHandler = (event) => {
      if (
        event.message && 
        (event.message.includes('ResizeObserver loop') || 
         event.message.includes('ResizeObserver loop completed with undelivered notifications'))
      ) {
        event.stopImmediatePropagation();
        event.preventDefault();
        console.log('ResizeObserver error suppressed');
        return false;
      }
    };

    // Add the event listener to both error and unhandledrejection events
    window.addEventListener('error', errorHandler, true);
    window.addEventListener('unhandledrejection', errorHandler, true);

    // Clean up
    return () => {
      window.removeEventListener('error', errorHandler, true);
      window.removeEventListener('unhandledrejection', errorHandler, true);
    };
  }, []);
};

export default useResizeObserverFix; 
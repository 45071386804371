import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaWhatsapp, FaPhone, FaEnvelope, FaTimes, FaComments } from 'react-icons/fa';
import './FloatingCTA.css';

const FloatingCTA = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCTA, setShowCTA] = useState(false);
  
  useEffect(() => {
    // Show CTA after user has scrolled a bit
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowCTA(true);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    // Show CTA after 5 seconds even if no scroll
    const timer = setTimeout(() => {
      setShowCTA(true);
    }, 5000);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);
  
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <AnimatePresence>
      {showCTA && (
        <motion.div 
          className="floating-cta"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
        >
          <button 
            className={`cta-toggle ${isOpen ? 'open' : ''}`} 
            onClick={toggleOpen}
          >
            {isOpen ? <FaTimes /> : <FaComments />}
            <span className="cta-label">{isOpen ? 'Close' : 'Contact Us'}</span>
          </button>
          
          <AnimatePresence>
            {isOpen && (
              <motion.div 
                className="cta-options"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3 }}
              >
                <motion.a 
                  href="https://wa.me/255765603466" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="cta-option whatsapp"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <FaWhatsapp />
                  <span>WhatsApp</span>
                </motion.a>
                
                <motion.a 
                  href="tel:+255765603466" 
                  className="cta-option phone"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <FaPhone />
                  <span>Call Us</span>
                </motion.a>
                
                <motion.a 
                  href="mailto:info@houseofcanvas.net" 
                  className="cta-option email"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <FaEnvelope />
                  <span>Email</span>
                </motion.a>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FloatingCTA; 